

const compareVersions = (currentVersion, latestVersion) => {
    // Check if currentVersion and latestVersion are not undefined or null
    if (!currentVersion || !latestVersion || !/^\d+(\.\d+)*$/.test(latestVersion)) {
      return 'Unable to determine the version information. Please check the latest version information manually.';
    }

    const current = currentVersion.split('.').map((v) => parseInt(v, 10));
    const latest = latestVersion.split('.').map((v) => parseInt(v, 10));

    if (current[0] < latest[0]) {
      return 'You are missing major updates which include significant new features and potential breaking changes.';
    } else if (current[1] < latest[1]) {
      return 'You are missing minor updates which include new features and improvements.';
    } else if (current[2] < latest[2]) {
      return 'You are missing patch updates which include important bug fixes and security patches.';
    } else {
      return 'Your version is up to date.';
    }
  };

  export default compareVersions;