import { Grid, Button, Box, Typography } from '@mui/material';
import appColors from '../common_comp/app_colors';
import techDetectIcon from '../assets/tech_detect_icon.svg'
import user_friendly_icon from '../assets/user_friendly_icon.svg'
import edge_insight_icon from '../assets/edge_insight_icon.svg'
import startegic_des_icon from '../assets/startegic_des_icon.svg'
import tech_insight_image from '../assets/tech_insight_image.png'

const ChooseTechPart = () => {

    const contentBox = (icon, title, desc, exampDesc) => (
        <Grid container  >
            <Grid item marginRight="20px">
                <img
                    src={icon}
                    alt='Optimize Performance'
                    style={{
                        width: '72px',
                        height: '80px',
                        objectFit: 'cover',
                        borderRadius: '8px',
                        marginBottom: '10px', // Optional margin below the image
                    }}
                />
            </Grid>
            <Grid item xs={9.7}>
                <Box>
                    <Typography
                        sx={{
                            fontFamily: "Lato",
                            fontWeight: 700,
                            fontSize: { xs: "12px", sm: "16px", md: "18px" },
                            color: '#333', // Replace with your color variable or hex code
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Lato",
                            fontWeight: 400,
                            fontSize: { xs: "12px", sm: "14px", md: "16px" },
                            color: '#666', // Replace with your color variable or hex code
                            marginTop: "10px", // Adjust top margin as needed
                        }}
                    >
                        {desc}       </Typography>
                </Box>
            </Grid>
        </Grid>
    );


    return (
        <section>
            <Grid container sx={{ marginTop: { xs: "70px", sm: "100px", md: "120px" },marginX:"auto", maxWidth: '1200px', alignItems: "center" }}>

                <Grid item xs={12} alignItems="center">
                    <Typography
                        sx={{
                            fontFamily: "Lato",
                            fontWeight: 600,
                            fontSize: { xs: "12px", sm: "16px", md: "40px" },
                            color: appColors.blackColor2,
                            textAlign: "center"
                        }}
                    >
                        Why Choose 
                        <span style={{ color: '#2B62A3' }}> Tech Analyzer?</span> <br />
                    </Typography>
                    <Typography sx={{
                        fontFamily: "Lato",
                        fontWeight: 400,
                        textAlign: "center",
                        marginTop: "10px",
                        marginX: '30px',
                        fontSize: { xs: "12px", sm: "16px", md: "16px" },
                        color: appColors.blackColor2,
                    }}>
                        In a digital landscape brimming with complexity, understanding the technologies behind a website is crucial for<br /> success. Tech Analyzer simplifies this process, offering unparalleled insights into the hidden tech stacks of any<br /> website with our website platform checker.                    </Typography>

                </Grid>

                <Grid container spacing={4} sx={{ margin: '20px auto auto', maxWidth: '1200px', alignItems: "center" }}>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {contentBox(techDetectIcon, "Comprehensive and Accurate Technology Detection", "Tech Analyzer provides an in-depth analysis of both visible and hidden technologies on any website. Whether it's server configurations or frontend libraries, get the complete picture with our website tech stack analyzer.")}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {contentBox(user_friendly_icon, "User-Friendly and Intuitive Interface", "Designed with simplicity in mind, Tech Analyzer offers a clean and straightforward user experience. With just a few clicks, obtain a detailed technology breakdown without any technical hassle."
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {contentBox(edge_insight_icon, "Regular Updates and Cutting-Edge Insights", "Stay updated with the latest technology trends. Tech Analyzer constantly refreshes its database, ensuring you get the most current information on emerging tools and frameworks."
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {contentBox(startegic_des_icon, "Actionable Intelligence for Strategic Decisions", "Use the insights from Tech Analyzer to inform your business strategies, optimize your technology stack, and gain a competitive edge. Make data-driven decisions that drive growth and innovation."
                        )}
                    </Grid>

                </Grid>
            </Grid>


            <Grid container sx={{ margin: '20px auto auto auto',justifyContent:"center", alignItems: "center" }}>
            <Grid container spacing={2}
                sx={{
                    width: '100%',
                    backgroundColor: '#EEF4FF',
                    marginTop: "40px",
                    direction: "row",
                    marginX:{ xs: "2px", sm: "2px", md: "100px" },
                    padding: { xs: "12px", sm: "14px", md: "30px" },
                    alignItems: "center",
                    borderRadius: "20px"
                    

                }}
            >

                <Grid item xs={6} >
                    <Typography
                        sx={{
                            fontFamily: "Lato",
                            fontWeight: 600,
                            fontSize: { xs: "12px", sm: "16px", md: "40px" },
                            color: appColors.blackColor2,
                        }}
                    >
                        Ready to Gain
                        <span style={{ color: '#2B62A3', fontWeight: 700, }}> Deep Technology Insights?</span> <br />
                    </Typography>
                    <Typography sx={{
                        fontFamily: "Lato",
                        fontWeight: 400,
                        marginTop: "10px",
                        fontSize: { xs: "12px", sm: "14px", md: "16px" },
                        color: appColors.blackColor2,
                    }}>
                        Analyze a Website Now using our website platform checker online!
                    </Typography>

                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: appColors.orangeColor,
                            color: 'white',
                            marginTop: "50px",
                            borderRadius: '8px',
                            height: '35px', // match height with TextField for alignment
                        }}
                    >
                        Start Analyzing Now
                    </Button>

                </Grid>


                <Grid item xs={6} >

                    <Box
                        component="img"
                        src={tech_insight_image}
                        alt='User Experience'
                        sx={{
                            width: '100%', // Makes the image responsive
                            maxWidth: '540px', // Sets a maximum width for the image
                            marginRight: '20px', // Adjust margin as needed
                        }}
                    />
                </Grid>


            </Grid>
            </Grid>

            
         
        </section>
    )
}

export default ChooseTechPart;