// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LookupPage from './components/pages/LookupPage.js';
import WebInfoPage from './components/pages/WebInfoPage.js';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LookupPage />} />
         <Route path="/web-info" element={<WebInfoPage />} />
      </Routes>
    </Router>
  );
}

export default App;