import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, TextField, Button,Box } from '@mui/material';


function PdfDialog(
    { email,
        companyName,
        phone, setEmail,
        setCompanyName,
        setPhone, open,
        onClose,
        sending,
        onAgree }) {


    return (
        <Dialog open={open} onClose={onClose}>
        <Box sx={{ padding: '20px' }}>
          <DialogTitle sx={{fontFamily:"Lato"}}>{"Send PDF via Email"}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{fontFamily:"Lato"}}>
              Enter the recipient email address, company name, and phone number to send the PDF.
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="companyName"
              label="Company Name"
              type="text"
              fullWidth
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="phone"
              label="Phone Number"
              type="tel"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            {sending ? (
              <CircularProgress size={24} />
            ) : (
                <Button
                onClick={() => onAgree(email, companyName, phone)}
                color="primary"
                disabled={!email || !companyName || !phone}
                sx={{
                  border:!email || !companyName || !phone ? '1px solid #D3D3D3':'1px solid #2196f3', // Blue border
                  borderRadius: '8px',
                  backgroundColor: !email || !companyName || !phone ? 'white' : '#2196f3',
                  color: !email || !companyName || !phone ? '#2196f3' : 'white',
                  marginLeft: 'auto', // Ensure button aligns to the right
                  marginRight: '12px',
                  paddingX: '10px',
                  paddingY: '2px',
                }}
              >
                Send
              </Button>
            )}
          </DialogActions>
        </Box>
      </Dialog>
      
    );
}

export default PdfDialog;
