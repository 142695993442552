import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import socialImage from '../assets/social_m_icon.svg'; // Import the logo image
import AppColors from '../common_comp/app_colors';

const categories = {
  COMPETENCIES: [
    "Digital Transformation",
    "Cyber security",
    "Data Analytics",
    "QA & Performance testing",
    "Cloud Computing",
    "Accessibility Services",
    "Application Dev. & Maintenance",
    "Staff Augmentation",
    "Growth Marketing Services"
  ],
  INDUSTRIES: [
    "Real estate & Property",
    "Food & Beverages",
    "Social Networking",
    "Banking Finance & Insurance",
    "Education & E-learning",
    "E-Commerce",
    "Retail",
    "Travel & Hospitality",
    "Media & Entertainment"
  ],
  MYINDUSTRIES: [
    "Healthcare",
    "Automotive",
    "Gaming",
    "Fitness",
    "Logistics",
    "Aviation",
    "Transport",
    "On-demand solutions"
  ],
  COMPANY: [
    "About Us",
    "Case Studies",
    "Contract Vehicles",
    "Blog",
    "Contact Us",
    "Sitemap",
    "Privacy Policy"
  ]
};

const ContactBoxPart = () => {
  return (
    <section>
 <Box sx={{ backgroundColor: '#EEF4FF', paddingLeft:{ xs: 2,sm:20,md:32,xl:32},paddingRight:{ xs: 2,sm:16,md:20,xl:20} ,paddingY:{ xs: 2,sm:5,md:5,xl:5}, marginTop:"40px" }}>
      <Grid container spacing={2} >
        {Object.keys(categories).map((category) => (
          <Grid item xs={3} sm={12} md={3}  key={category}>
            <Typography  sx={{ 
                color: category !== 'MYINDUSTRIES'?"black":"transparent",
                marginBottom: 4 ,
                fontFamily:"Lato",
                fontWeight:600,
                color:AppColors.blackColor,
                fontSize: { xs: "10px", sm: "12px", md: "16px" ,xl:"16px"},}}>
              {category}
            </Typography>

            {categories[category].map((item, index) => (
              <Typography key={index} sx={{color:AppColors.blackColor3,fontFamily:"Lato", marginBottom: 0.7,fontWeight:400, fontSize: { xs: "8px", sm: "10px", md: "14px" ,xl:"16px"},}}>
                {item}
              </Typography>
            ))}
          </Grid>
        ))}
      </Grid>
      
    </Box>

    <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2, marginTop: 2, paddingLeft:  { xs: 2, sm: 2, md: 10, xl:30 }, paddingRight: { xs: 2, sm: 2, md: 5, xl:10 } }}>
  <Grid item>
    <Typography sx={{fontFamily:"Lato", fontWeight: 400, fontSize: { xs: "10px", sm: "12px", md: "14px", xl: "14px" } }}>
      Copyright © 2023 InfoStride Technologies All rights reserved. Privacy Policy | Site Map
    </Typography>
  </Grid>
  <Grid item>
    <img src={socialImage} alt="Logo" className="logo" />
  </Grid>
</Grid>
    </section>
   
    
  );
};

export default ContactBoxPart;