import React, { useState } from 'react';
import { InputAdornment, Grid, Toolbar, Box, Typography, TextField, Button } from '@mui/material';
import logo from '../assets/infoStrideLogo.svg'; // Import the logo image
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import innovative_icon from '../assets/innovative_icon.svg';
import optimize_prformamce_icon from '../assets/optimize_prformamce_icon.svg';
import user_exp_icon from '../assets/user_exp_icon.svg';
import appColors from '../common_comp/app_colors';
import webInfoVM from '../pages_view_model/WebInfoViewModel';


const HeaderSearchPart = ({ setLoader }) => {
  const navigate = useNavigate();
  const [webAddress, setWebAddress] = useState(null);


  const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  };

  const images = importAll(require.context('../assets/languages', false, /\.svg$/));

  const isWebsiteValid = (website) => {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return pattern.test(website);
  };




  const handleSubmit = async () => {
    if (isWebsiteValid(webAddress)) {
      setLoader(true);
      const result = await webInfoVM.generateResponse(webAddress);

      const dataRes = JSON.stringify(result, null, 2); // null and 2 are optional parameters for pretty printing


      // setLoader(false);

      setLoader(false);
      navigate('/web-info', { state: { webAddress, dataRes } });
    } else {
      toast.error('Please enter a valid Web address.', {
        position: 'bottom-right',
        autoClose: 3000,
        color: "red",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (<section>
    <Box position="static" style={{ backgroundColor: appColors.whiteColor }}>
      <Toolbar style={{ flex: 1, display: 'flex', alignItems: 'center', padding: '0 100px' }}>
        <img src={logo} alt="Logo" className="logo" style={{ width: '250px' }} />
        <div style={{ flexGrow: 1 }}></div>
      </Toolbar>
    </Box>


    <Box
      bgcolor="#EEF4FF"
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="30px 25px"
      borderRadius="10px"
      margin="20px 40px"
    >
      <Typography sx={{
        fontFamily: "Lato",
        fontWeight: 700,
        lineHeight: 1, // Removes extra space between lines
        textAlign: "center",
        fontSize: { xs: "22px", sm: "48px", md: "56px" },
        color: appColors.blueColor2,
      }} >
        Discover The Technologies
      </Typography>
      <Typography sx={{
        fontFamily: "Lato",
        fontWeight: 700,

        lineHeight: 1.5, // Removes extra space between lines
        fontSize: { xs: "22px", sm: "48px", md: "56px" },
      }}>
        Behind Any Website
      </Typography>
      <Typography sx={{
        fontFamily: "Lato",
        fontWeight: 400,
        fontSize: { xs: "7px", sm: "16px", md: "16px" },
        color: appColors.blackColor2,
        marginX: { xs: "10px", sm: "10px", md: "10px" },
        marginTop: "30px",
        textAlign: "center",
      }} >
        Effortlessly uncover the tools, frameworks, and languages that power any site with our website<br /> platform checker online. Gain insights to optimize your own site or understand your competitors.<br /> Start analyzing with our website platform checker today!
      </Typography>
      <Grid marginTop="30px">
        <TextField
          type="url"
          id="webAddress"
          name="webAddress"
          placeholder="Enter Website URL"
          value={webAddress}
          onChange={(e) => setWebAddress(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: 'white',
              '& input': {
                height: '50px', // Set the height directly on the input
                boxSizing: 'border-box', // Ensure padding and border are included in the height
                WebkitTextFillColor: 'black', // Set initial color to black for better contrast
                '&:-webkit-autofill': {
                  WebkitBoxShadow: '0 0 0 1000px white inset',
                },
              },
              width: { xs: "300px", sm: "350px", md: "650px" },
            },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{
                    backgroundColor: appColors.orangeColor,
                    color: 'white',
                    fontSize: { xs: "10px", sm: "12px", md: "14px" },
                    borderRadius: '8px',
                    height: '35px', // match height with TextField for alignment
                  }}
                >
                  Analyze Now
                </Button>
              </InputAdornment>
            ),
            disableUnderline: true, // Removes the default underline
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '50px', // Ensure consistent height for the entire input root
              '& fieldset': {
                border: 'none', // Remove border
              },
              borderRadius: '8px',
            },
          }}
        />
      </Grid>

      <Box
        sx={{
          marginTop: "50px",
          marginX: { xs: "5px", sm: "60px", md: "80px" },
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px', // Space between items
          justifyContent: 'center', // Center the items
        }}
      >
        {Object.keys(images).map((key, index) => (
          <Box
            key={index}


          >
            <Box
              component="img"
              src={images[key]}
              alt={`img-${index}`}
              sx={{
                width: { md: "100%", xs: '80px', sm: "100%", },
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>



    <Grid container spacing={3} sx={{ marginTop: '40px', marginBottom: '40px', maxWidth: '100%', paddingLeft: '10%', paddingRight: '10%' }}>
      <Grid item xs={12} md={6}>
        <Typography sx={{
          fontFamily: "Lato",
          fontWeight: 600,
          fontSize: { xs: "12px", sm: "16px", md: "40px" },
          color: appColors.blackColor2,
        }}>
          Why Understanding <br />
          <span style={{ color: '#2B62A3', fontWeight: 700, }}>Website Technologies</span> <br />
          Is Crucial?
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography sx={{
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: { xs: "12px", sm: "16px", md: "16px" },
          color: appColors.blackColor2,
        }}>
          In today’s fast-paced digital world, staying ahead requires a deep understanding of the technologies behind successful websites. A recent study found that businesses leveraging modern web technologies saw a 45% increase in customer engagement and a 30% boost in conversion rates.
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={4} justifyContent="center" sx={{ maxWidth: '100%', paddingLeft: '10%', paddingRight: '10%' }}>
      <Grid item xs={12} sm={3} md={4}>
        <Box >
          <img
            src={optimize_prformamce_icon}
            alt='Optimize Performance'
            style={{
              width: '72px',
              height: '80px',
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />
          <Typography sx={{
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: { xs: "12px", sm: "16px", md: "18px" },
            color: appColors.blackColor2,
          }}>Optimize Performance and Security</Typography>
          <Typography marginTop="10px" sx={{
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: { xs: "12px", sm: "14px", md: "16px" },
            color: appColors.blackColor2,
          }}>
            Use our website technology checker to know the technology stack of your website, allowing you to fine-tune performance and enhance security measures, ensuring a seamless and safe user experience.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={3} md={4}>
        <Box >
          <img
            src={innovative_icon}
            alt='Innovative'
            style={{
              width: '72px',
              height: '80px',
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />
          <Typography sx={{
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: { xs: "12px", sm: "16px", md: "18px" },
            color: appColors.blackColor2,
          }}>Stay Competitive and Innovative</Typography>
          <Typography marginTop="10px" sx={{
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: { xs: "12px", sm: "14px", md: "16px" },
            color: appColors.blackColor2,
          }}>
            By analyzing the latest technologies used by top websites with our web page analyzer, you can adopt innovative tools and frameworks to stay ahead of your competition.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={3} md={4}>
        <Box >
          <img
            src={user_exp_icon}
            alt='User Experience'

            style={{
              width: '72px',
              height: '80px',
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />
          <Typography sx={{
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: { xs: "12px", sm: "16px", md: "18px" },
            color: appColors.blackColor2,
          }}>Improve User Experience</Typography>
          <Typography marginTop="10px" sx={{
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: { xs: "12px", sm: "14px", md: "16px" },
            color: appColors.blackColor2,
          }}>
            Understanding the tools that enhance user interactions through our analyze website technology feature can help you create more engaging and responsive websites, leading to higher satisfaction and retention.
          </Typography>
        </Box>
      </Grid>

    </Grid>
  </section>)
};

export default HeaderSearchPart;
