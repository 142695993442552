import backgroundImage from '../assets/pdf_background_image.png'; // Replace with your background image path
import AppColors from '../common_comp/app_colors';
import compareVersions from '../common_comp/compare_version';

const pdfContent = (dataResponse) => {
  const PAGE_THRESHOLD = 990;
const PAGE_HEIGHT = 165;
let currentPageCount = 0;

const checkSize = (index) => {
  currentPageCount ++
  let totalSize = currentPageCount * PAGE_HEIGHT;

  if (totalSize > PAGE_THRESHOLD ||index===4) {
    currentPageCount = 0;
    return true;
  } else {
    return false;
  }
};

  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Static HTML</title>
      <style>
        .section-container {
          background-image: url(${backgroundImage});
          background-size: cover;
          background-position: center;
          padding: 20px;
        }
        .text-container {
          font-size: 25px;
          color: #2b62a3;
          font-weight: 800;
          font-family: 'Lato', sans-serif;
        }
        .title-text-container {
          font-size: 10px;
          color: #2b62a3;
          margin: 8px 10px 8px 0;
          font-weight: 800;
          font-family: 'Lato', sans-serif;
        }
        .value-text-container {
          font-size: 10px;
          color: #212121;
          margin: 8px 0 8px 2px;
          font-weight: 400;
          font-family: 'Lato', sans-serif;
        }
        .blue-gradient {
          background: linear-gradient(90deg, #5387B3 0%, #243A4D 69%);
        }
        .blue-gradient-text {
          color: #ffffff;
          text-align:center;
          font-size: 12px; 
          font-weight: 800;
        }
        .dark-grey {
          color: #212121;
        }
        .container {
          border: 2px solid #5387B3;
          width: auto;
          padding: 0;
          display: flex;
          flex-direction: row;
          margin-left: 40px;
          margin-right: 40px;
          margin-top: 12px;
          border-radius: 20px;
        }
        .grid {
          display: grid;
          grid-template-columns: 1fr;
          gap: 16px;
          
        }
        .grid-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #5387B3;
          background: linear-gradient(90deg, #5387B3 0%, #243A4D 69%);
          border-top-left-radius: 17px;
          border-bottom-left-radius: 17px;
          padding: 16px;
          width:100px;
        }
       
        .typography-small {
          font-family: "Lato, sans-serif";
          font-weight: 400;
          font-size: 10px;
          margin-top: 8px;
          color: #FFFFFF;
          text-align:center;
        }
        .img-style {
          height: 30px;
          margin-top:10px;
          width: 30px;
        }
       
     
      </style>
    </head>
    <body >
      <section class="section-container">
        <div style="display: flex; align-items: center; justify-content: center; margin: 20px 40px; flex-direction: column;">
          <div class="text-container" style="font-size:18px;">
            ${dataResponse.data[0]?.title}
          </div>
          <div class="text-container" style="font-size: 12px; color: #212121; font-weight: 400; margin-top: 10px;">
            ${dataResponse.data[0]?.url}
          </div>
          <div style="margin-top: 25px; width: 100%; height: 3px; background-color: #2b62a3;"></div>
        </div>
        <div style="display: flex; flex-direction: row; border: 2px solid #5387B3; margin-top: 40px; margin-bottom: 30px; margin-left: 40px; margin-right: 40px; border-radius: 20px;">
          <div class="blue-gradient" style="display: flex; align-items: center; justify-content: center; border-top-left-radius: 17px; border-bottom-left-radius: 17px; width: 130px;">
            <p class="blue-gradient-text" >About</p>
          </div>
          <div style="padding: 16px 16px 16px 25px;">
            <div style="display: flex; flex-direction: row; align-items: center;">
              <p class="title-text-container">Title:</p>
              <p class="value-text-container">${dataResponse.data[0]?.title}</p>
            </div>
            <div style="display: flex; flex-direction: row; align-items: center;">
              <p class="title-text-container">Copyright:</p>
              <p class="value-text-container">${dataResponse.data[0].copyright ?? ''}</p>
            </div>
            <div style="display: flex; flex-direction: row; align-items: center;">
              <p class="title-text-container">Company Information:</p>
              <p class="value-text-container">Inferred company name: ${dataResponse.data[0].inferredCompanyName ?? ''}</p>
            </div>
            <div style="display: flex; flex-direction: row; align-items: center;">
              <p class="title-text-container">Signals:</p>
              <p class="value-text-container">Technology spend: ${dataResponse.data[0].technologySpend ?? ''}</p>
            </div>
            <div style="display: flex; flex-direction: row; align-items: center;">
              <p class="title-text-container">Locale:</p>
              <p class="value-text-container">Region: ${dataResponse.data[0].ipRegion ?? ''}, Countries: ${dataResponse.data[0].ipCountry ?? ''}, Languages: ${dataResponse.data[0].ipCountry ?? ''}</p>
            </div>
            <div style="display: flex; flex-direction: row; align-items: center;">
              <p class="title-text-container">Keywords:</p>
              <p class="value-text-container">${dataResponse.data[0].keywords?.join(', ') ?? ''}</p>
            </div>
          </div>
        </div>
        <div>
          <div class="text-container" style="font-size: 18px;margin-bottom:15px; color: #2b62a3; font-weight: 800; display: flex; align-items: center; justify-content: center;">
            Technologies Stack
          </div>
          <div class="grid" >
            ${dataResponse.data[0].technologies.map((tech, index) => `
              ${checkSize(index) ===true ? `
                <div style=" width: 100%; height: 90px;"></div>
              ` : `
                <div class="container ">
                  <div class="grid-item">
                    <div class="blue-gradient-text">
                      ${tech.categories && tech.categories.length > 0 ? tech.categories[0].name : ""}
                    </div>
                    <img src="${tech.img_url}" class="img-style" />
                    <div class="typography-small">
                      ${tech.slug}
                    </div>
                  </div>
                  <div style="padding: 16px 16px 16px 25px;">
                    <div>
                      <span class="title-text-container">Impact:</span> 
                      <span class="value-text-container">${compareVersions(tech.versions[0], tech.latest_version)}</span>
                    </div>
                    <div style="margin-top: 15px; ">
                      <span class="title-text-container">Current Version:</span> 
                      <span class="value-text-container">${tech.versions.length ? tech.versions[0] : "N/A"}</span>
                    </div>
                    <div style="margin-top: 15px;">
                      <span class="title-text-container">Latest Version:</span> 
                      <span class="value-text-container">${tech.latest_version === "" || tech.latest_version === null ? "N/A" : tech.latest_version}</span>
                    </div>
                  
                  </div>
                </div>
              `}
            `).join('')}
          </div>
        </div>
        <div style="display: flex; align-items: center; justify-content: center; margin-top: 60px; flex-direction: row;">
        <div class="text-container" style="font-size:15px;color:#c5c6c7">
         Powerd By 
        </div>
        <div class="text-container" style="font-size:15px;margin-left:5px;">
         Infostride
       </div>
        
      </div>
      </section>
    </body>
    </html>
  `;
};

export default pdfContent;
