

 const AppColors = {
    primaryColor:'#3d4962',
    whiteColor: '#ffffff',
    whiteColor2: '#FBFBFB',

    blackColor: '#000000',
    blackColor2: '#565656',
    blackColor3: '#252B42',

    blueColor: '#2b62a3',
    blueColor2: '#1e90ff',
    greyColor: '#f5f5f5',
    greyColor2:'#e5e9ed',
    borderColor: '#e0e0e0',
    blueGradientColor1: '#5387B3',
    blueGradientColor2: '#243A4D',
    lightBlueGradientColor1: '#0285E4',
    lightBlueGradientColor2: '#9AC4E3',
    darkGreyColor:'#212121',
    orangeColor:"#EC5C2E"
  }


  export default AppColors