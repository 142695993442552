import React, { useState, useEffect } from 'react';
import headerSearchPart from '../lookup_component/header_search_part';
import exploreKeyTechnologyPart from '../lookup_component/explore_key_tech_part';
import chooseTechPart from '../lookup_component/choose_tech_part';
import FrequentlyQuestionPart from '../lookup_component/frequently_question_part';
import ContactBoxPart from '../lookup_component/contact_part';



import { CircularProgress, Box, Grid } from '@mui/material';
import appColors from '../common_comp/app_colors';

function LookupPage() {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (loader) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [loader]);

  const pdfLoaderView = () => {
    return (
      <section>
        <Grid
          container
          sx={{
            height: '100vh',
            position: 'relative',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 22,
          }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <CircularProgress variant="indeterminate" style={{ color: appColors.whiteColor ,height:"50px",width:"50px"}} />
          </Box>
          <div
            className="text-container"
            style={{
              margin: '10px',
              fontSize: '30px',
              color: appColors.whiteColor,
              fontWeight: 600,
              marginTop: 2,
            }}
          >
            Loading.....
          </div>
        </Grid>
      </section>
    );
  };

  return (
    <section>
      <Grid
        sx={{
          zIndex: -4,
          position: 'absolute',
        }}
      >
        {headerSearchPart({setLoader})}
        {exploreKeyTechnologyPart()}
        {chooseTechPart()}
        {FrequentlyQuestionPart()}
        {ContactBoxPart()}
      </Grid>

      {loader && pdfLoaderView()}

    </section>
  );
}

export default LookupPage;
