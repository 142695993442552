import APIClient from "../api_handler/APIClient"
 
class WebInfoVM {
   
 
 
 
    static async generateResponse(url) {
        const param = {
            "url": url
        }
        console.log("param",param)
        const result = await APIClient.postRequest('get_latest_versions/', param, false)
        if (result != null) {
            return result
        }
    }



    static async sendPDFToAPI(email, pdfData,companyName,phone) {
        const param = {
            email: email,
            pdfData: pdfData,
            company_name:companyName,
            contact_no:phone
        };
    
        console.log("param", param);
    
        try {
            const result = await APIClient.postDocumentRequest('send_pdf', param, false);
            if (result) {
                return result;
            } else {
                throw new Error('No result returned from API');
            }
        } catch (error) {
            console.error("Error sending PDF to API:", error);
            throw error;
        }
    }

 
 
 
 
 
 
}
 
export default WebInfoVM