import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import appColors from '../common_comp/app_colors';


const ExpansionBox = ({ serialNumber, question, answer }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        boxShadow: '0px 32.7px 65.4px 0px rgba(22, 25, 79, 0.05)',
        borderRadius: 2,
        padding: 1.5,
        marginBottom: 2,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #CAD6F1', // Added border color
      }}
    >
      <Box
        onClick={handleToggle}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Typography sx={{ flexShrink: 0,  marginLeft: 2 ,
        fontWeight: 500,
        fontFamily: "Open Sans, sans-serif",
        color: appColors.blackColor2,
            fontSize: { xs: "10px", sm: "16px", md: "20px" },}}>{serialNumber}.</Typography>
        <Typography sx={{ flexGrow: 1, marginLeft: 2 , fontFamily: "Open Sans, sans-serif",
                            fontWeight: 500,
                            fontSize: { xs: "10px", sm: "16px", md: "20px" },
                            color: appColors.blackColor2,
                            }}>{question}</Typography>
        <IconButton sx={{ width: 34, height: 34 }}>
          {open ? <RemoveIcon /> : <AddIcon />}
        
        </IconButton>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ paddingTop: 2,marginRight: 2,marginLeft:6 }}>
          <Typography sx={{fontSize: { xs: "10px", sm: "12px", md: "16px" },fontWeight: 500,color: appColors.blackColor2,}}>{answer}</Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ExpansionBox;