import { Container, Grid, Typography } from '@mui/material';
import appColors from '../common_comp/app_colors';
import ExpansionBox from '../common_comp/expension_box';



const FrequentlyQuestionPart = () => {





    return (
        <section>

            <Grid container sx={{ margin: '70px auto auto', maxWidth: '1200px', alignItems: "center" }}>

                <Grid item xs={12} alignItems="center">
                    <Typography
                        sx={{
                            fontFamily: "Lato",
                            fontWeight: 600,
                            fontSize: { xs: "12px", sm: "16px", md: "40px" },
                            color: appColors.blackColor2,
                            textAlign: "center"
                        }}
                    >
                        Frequently Asked
                        <span style={{ color: '#2B62A3' }}> Questions</span> <br />
                    </Typography>

                </Grid>


                <Container sx={{ paddingTop: 3 }}>
                    <ExpansionBox
                        serialNumber="1"
                        question="What is Tech Analyzer and how does it work?"
                        answer="Tech Analyzer is an online tool designed to analyze websites and reveal their underlying technologies. Simply enter a website URL into our website platform checker, and Tech Analyzer scans and provides detailed insights into its tech stack, including web servers, programming languages, and more."
                    />
                    <ExpansionBox
                        serialNumber="2"
                        question="How can Tech Analyzer benefit my business?"
                        answer="By using Tech Analyzer, you can gain a comprehensive understanding of your competitors’ and industry leaders’ technology choices. This insight, gained through our analyze website technology feature, enables you to optimize your own tech stack for better performance, security, and user experience."
                    />

                    <ExpansionBox
                        serialNumber="3"
                        question="Is Tech Analyzer suitable for non-technical users?"
                        answer="Yes, Tech Analyzer is designed to be user-friendly and intuitive. Even if you're not a technical expert, you can easily navigate the platform to analyze website and check its technology stack with our website technology checker."
                    />
                    <ExpansionBox
                        serialNumber="4"
                        question="What types of technologies does Tech Analyzer identify?"
                        answer="Tech Analyzer detects a wide range of technologies, including web servers, content delivery networks (CDNs), programming languages, JavaScript libraries, frameworks, analytics tools, and more. It serves as a comprehensive website tech stack analyzer."
                    />
                    <ExpansionBox
                        serialNumber="5"
                        question="Can Tech Analyzer be used to analyze multiple websites?"
                        answer="Yes, Tech Analyzer allows you to analyze multiple websites. Whether you want to find the tech stack of a company or compare several sites, our website platform checker makes it easy to gather technology insights."
                    />
                    <ExpansionBox
                        serialNumber="6"
                        question="How often is Tech Analyzer’s database updated?"
                        answer="Tech Analyzer regularly updates its database to include the latest technologies and trends. This ensures that you receive up-to-date information when using our website platform checker online to analyze websites."
                    />
                    <ExpansionBox
                        serialNumber="7"
                        question="Is there a free trial available for Tech Analyzer?"
                        answer="Yes, Tech Analyzer offers a free trial period where you can analyze a limited number of websites. This allows you to experience the capabilities of our website framework checker before committing to a subscription."
                    />
                    <ExpansionBox
                        serialNumber="8"
                        question="Can Tech Analyzer help identify the technology stack of a company’s website?"
                        answer="Absolutely. Tech Analyzer is ideal for identifying the technology stack of any company’s website. Whether you’re researching competitors or potential business partners, our website tech stack analyzer provides detailed insights into their tech infrastructure."
                    />



                </Container>

            </Grid>

        </section>
    )
}

export default FrequentlyQuestionPart;