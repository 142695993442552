import appColors from '../common_comp/app_colors';
import webUrlIcon from '../assets/web_url_icon.svg'
import scannningIcon from '../assets/scanning_icon.svg'
import detailIcon from '../assets/detail_icon.svg'
import searchWebImage from '../assets/search_web_image.png'
// import chooseTechPart from '../lookup_component/choose_tech_part'; 

import webEssIcon from '../assets/web_ess_icon.svg'
import frontendTechIcon from '../assets/fontend_tech_icon.svg'
import webSettingsIcon from '../assets/web_settings_icon.svg'
import performanceIcon from '../assets/performance.svg'

import { Grid, Box, Typography } from '@mui/material';

const ExploreKeyTechnologyPart = () => {

  const contentBox = (icon, title, desc, exampDesc) => (
    <Box sx={{
      borderRadius: '17px',
      padding: "30px",
      backgroundColor: appColors.blueGradientColor1,
      background: `linear-gradient(75deg, ${appColors.lightBlueGradientColor1} 30%, ${appColors.lightBlueGradientColor2} 100%)`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Box>
        <img
          src={icon}
          alt='User Experience'
          style={{
            width: '72px',
            height: '80px',
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
        <Typography sx={{
          fontFamily: "Lato",
          fontWeight: 800,
          marginTop: "10px",
          fontSize: { xs: "12px", sm: "16px", md: "20px" },
          color: appColors.whiteColor,
          position: "relative",
          '&::after': {
            content: '""',
            position: "absolute",
            width: "202px",
            height: "1px",
            backgroundColor: appColors.whiteColor,
            bottom: "-4px",
            left: 0,
          }
        }}>{title}</Typography>

        <Typography sx={{
          fontFamily: "Latof",
          fontWeight: 400,
          marginTop: "20px",
          fontSize: { xs: "12px", sm: "14px", md: "16px" },
          color: appColors.whiteColor2,
        }}>
          {desc}
        </Typography>
        <Typography sx={{
          fontFamily: "Lato",
          fontWeight: 400,
          marginTop: "20px",
          fontSize: { xs: "12px", sm: "14px", md: "16px" },
          color: appColors.whiteColor2,
        }}>
          <span style={{ fontWeight: 700 }}>Examples: </span> {exampDesc}
        </Typography>
      </Box>
    </Box>
  );



  return (
    <section>
      <Grid container sx={{ marginTop:"70px", maxWidth: '100%', paddingLeft: '10%', paddingRight: '10%', alignItems: "center" }}>
        <Grid item xs={12} alignItems="center">
          <Typography
            sx={{
              fontFamily: "Lato",
              fontWeight: 600,
              fontSize: { xs: "12px", sm: "16px", md: "40px" },
              color: appColors.blackColor2,
              textAlign: "center"
            }}
          >
            Explore Key Technologies With
            <span style={{ color: '#2B62A3' }}> Tech Analyzer</span> <br />
          </Typography>
          <Typography sx={{
            fontFamily: "Lato",
            fontWeight: 400,
            textAlign: "center",
            marginTop: "10px",
            fontSize: { xs: "12px", sm: "16px", md: "16px" },
            color: appColors.blackColor2,
          }}>
            Tech Analyzer unveils the essential technologies driving any website. From backend infrastructure to frontend enhancements,<br /> understand the core components that power and optimize online experiences with our website tech stack analyzer.
          </Typography>

        </Grid>
      </Grid>


      <Grid
        sx={{
          width: 'calc(100% - 200px)', // Adjust width accounting for margins
          height: { xs: "0px", sm: "0px", md: "1050px" }, // Adjust height as needed
          backgroundColor: '#EEF4FF',
          zIndex: -4,
          marginTop: "360px",
          position: "absolute",
          marginLeft: "100px", // Margin on the left side
          marginRight: "100px", // Margin on the right side
          clipPath: 'polygon(0 25%, 100% 0, 100% 100%, 0% 100%)',
          '@media (max-width: 600px)': {
            marginLeft: "20px", // Adjust for smaller screens
            marginRight: "20px", // Adjust for smaller screens
            width: 'calc(100% - 40px)', // Adjust width for smaller screens
          },
        }}
      >
      </Grid>


      <Grid container spacing={4} sx={{ marginTop:"10px", maxWidth: '100%', paddingLeft: '10%', paddingRight: '10%', alignItems: "center" }}>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          {contentBox(webEssIcon, "Website Essentials", "Tech Analyzer identifies critical components such as web servers, CDNs, and SSL/TLS certificates. These technologies ensure website reliability, security, and speed, forming the backbone of any online presence.", "Web Servers (Apache, Nginx), CDNs (Cloudflare), SSL/TLS Certificates (Let’s Encrypt)")}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {contentBox(frontendTechIcon, "Frontend Technologies", "Tech Analyzer highlights the JavaScript libraries, UI frameworks, and font scripts used to create dynamic, responsive, and visually appealing websites. These technologies are key to delivering engaging user interfaces.", "JavaScript Libraries (React, Vue.js), UI Frameworks (Bootstrap), Font Scripts (Google Fonts)")}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {contentBox(webSettingsIcon, "Backend Technologies", "Tech Analyzer uncovers the backend technologies responsible for processing data and handling server operations, ensuring smooth and efficient website functionality.", "Programming Languages (Python, PHP), Web Frameworks (Django, Ruby on Rails)")}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {contentBox(performanceIcon, "Performance and Analytics", "Tech Analyzer detects analytics and performance tools that provide insights into user interactions and optimize website performance, essential for maintaining high engagement and speed.", "Analytics Tools (Google Analytics), Tag Managers (Google Tag Manager), Performance Monitors (GTmetrix)")}
        </Grid>

      </Grid>





      <Grid style={{
        // position: "absolute",
        position:"relative",
        zIndex: 22,
        width: "calc(100% - 130px)",
        left: "50%",
        marginTop: "70px",
        transform: "translateX(-50%)",
      }}>

        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: 600,
            fontSize: { xs: "12px", sm: "16px", md: "40px" },
            color: appColors.blackColor2,
            textAlign: "center"
          }}
        >
          Analyze Any Website In
          <span style={{ color: '#2B62A3' }}> 3 Simple Steps</span> <br />
        </Typography>



        <Grid container sx={{
          justifyContent:"center",
          // marginLeft: { xs: "20px", sm: "40px", md: "80px", lg: "155px" },
          marginTop: "30px"
        }} >
          <Grid item>
            <Box
              component="img"
              src={searchWebImage}
              alt='User Experience'
              sx={{
                width: '100%', // Makes the image responsive
                maxWidth: '540px', // Sets a maximum width for the image
                marginRight: '20px', // Adjust margin as needed
              }}
            />
          </Grid>



          <Grid container xs={12} sm={6} md={4}>
            <Grid container  >
              <Grid item  marginRight={1}>
                <img
                  src={webUrlIcon}
                  alt='Optimize Performance'
                  style={{
                    width: '72px',
                    height: '80px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    marginBottom: '10px', // Optional margin below the image
                  }}
                />
              </Grid>
              <Grid item  xs={9} sm={9} md={9}>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 700,
                      fontSize: { xs: "10px", sm: "16px", md: "18px" },
                      color: '#333', // Replace with your color variable or hex code
                    }}
                  >
                    Enter the Website URL
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 400,
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      color: '#666', // Replace with your color variable or hex code
                      marginTop: "10px", // Adjust top margin as needed
                    }}
                  >
                    Simply input the URL of the website you want to analyze using our website framework checker and let Tech Analyzer do the rest.
                  </Typography>
                </Box>
              </Grid>
            </Grid>


            <Grid container  >
              <Grid item marginRight={1}>
                <img
                  src={scannningIcon}
                  alt='Optimize Performance'
                  style={{
                    width: '72px',
                    height: '80px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    marginBottom: '10px', // Optional margin below the image
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 700,
                      fontSize: { xs: "10px", sm: "16px", md: "18px" },
                      color: '#333', // Replace with your color variable or hex code
                    }}
                  >
                    Automated Scanning
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 400,
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      color: '#666', // Replace with your color variable or hex code
                      marginTop: "10px", // Adjust top margin as needed
                    }}
                  >
                    Tech Analyzer scans the site to detect and compile a comprehensive list of technologies in use, making it a robust web page analyzer.                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container  >
              <Grid item marginRight={1}>
                <img
                  src={detailIcon}
                  alt='Optimize Performance'
                  style={{
                    width: '72px',
                    height: '80px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    marginBottom: '10px', // Optional margin below the image
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 700,
                      fontSize: { xs: "10px", sm: "16px", md: "18px" },
                      color: '#333', // Replace with your color variable or hex code
                    }}
                  >
                    Detailed Insights
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 400,
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      color: '#666', // Replace with your color variable or hex code
                      marginTop: "10px", // Adjust top margin as needed
                    }}
                  >
                    Receive an easy-to-understand report detailing the technologies powering the website, helping you to check website technology effectively.                  </Typography>
                </Box>
              </Grid>
            </Grid>


          </Grid>



        </Grid>

      </Grid>







    </section>)
}
export default ExploreKeyTechnologyPart;