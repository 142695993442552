import React, { useEffect, useRef, useState } from 'react';
import pdfContent from '../pdf_comp/pdf_ui';
import compareVersions from '../common_comp/compare_version';
import PdfDialog from '../pdf_comp/pdf_dialog';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/infoStrideLogo.svg'; // Import the logo image
import DownloadIcon from '@mui/icons-material/Download';
import html2pdf from 'html2pdf.js';
import appColors from '../common_comp/app_colors'
import AppColors from '../common_comp/app_colors'

import webInfoVM from '../pages_view_model/WebInfoViewModel';
import { CircularProgress, Container, Typography, Button, Box, Grid } from '@mui/material';
import backgroundImage from '../assets/pdf_background_image.png'; // Replace with your background image path
import { useLocation } from 'react-router-dom';





function WebInfoPage() {
  const [dataResponse, setResponse] = useState(null);
  const contentRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false); // Use a separate state for the dialog
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [sending, setSending] = useState(false);
  const location = useLocation();
  const { webAddress,dataRes } = location.state;


  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(webAddress);
        setResponse(null); 
        const parsedObject = JSON.parse(dataRes);
        // const result = await webInfoVM.generateResponse(webAddress);
        // console.log("Here is the response", result);
        setResponse(parsedObject);
        // setResponse({
        //   "data": [
        //     {
        //       "url": "https://downtowntx.org/",
        //       "technologies": [
        //         {
        //           "slug": "anima",
        //           "name": "Anima",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [],
        //           "latest_version": "3.0.9",
        //           "Version_Description": "The latest version of Anima is 3.0.9",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrmVNwpiv7mT4hRcVRAgbCFqQdTZteA2FuhqH6zLZ9wYHSe1k&s"
        //         },
        //         {
        //           "slug": "google-analytics",
        //           "name": "Google Analytics",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 10,
        //               "slug": "analytics",
        //               "name": "Analytics"
        //             }
        //           ],
        //           "latest_version": "4",
        //           "Version_Description": "The latest version of Google Analytics is 4",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLDC-6HHEnakqV-ti2utay99AutYiC9XiTi-j27evc9yI9xKq8&s"
        //         },
        //         {
        //           "slug": "google-hosted-libraries",
        //           "name": "Google Hosted Libraries",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 31,
        //               "slug": "cdn",
        //               "name": "CDN"
        //             }
        //           ],
        //           "latest_version": "2.0.0",
        //           "Version_Description": "The latest version of Google Hosted Libraries is 2.0.0",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRT0-f_CsyrvIFkLRDuRbhWJ38AQ2jWCxpJnsUO94EJNZQXvc&s"
        //         },
        //         {
        //           "slug": "digicert",
        //           "name": "DigiCert",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 70,
        //               "slug": "ssl-tls-certificate-authorities",
        //               "name": "SSL/TLS certificate authorities"
        //             }
        //           ],
        //           "latest_version": "",
        //           "Version_Description": "The latest version of DigiCert is ",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1Y8ktSV2J8Wkes1LnEwiCYMu3IgtCJZ44h9GQIdWTRLMO_nIt&s"
        //         },
        //         {
        //           "slug": "apache-http-server",
        //           "name": "Apache HTTP Server",
        //           "cpe": "cpe:2.3:a:apache:http_server:2.4.29:*:*:*:*:*:*:*",
        //           "versions": [
        //             "2.4.29"
        //           ],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 22,
        //               "slug": "web-servers",
        //               "name": "Web servers"
        //             }
        //           ],
        //           "latest_version": "2.4.59",
        //           "Version_Description": "The latest version of Apache HTTP Server is 2.4.59 and the version you have used is 2.4.29",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7Z2KTClKmkeLIOXGrTn5_rMdVJNZFtP2-s024jHAQuwXPS4g&s"
        //         },
        //         {
        //           "slug": "jquery-ui",
        //           "name": "jQuery UI",
        //           "cpe": "cpe:2.3:a:jquery:jquery_ui:1.12.1:*:*:*:*:*:*:*",
        //           "versions": [
        //             "1.12.1"
        //           ],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 59,
        //               "slug": "javascript-libraries",
        //               "name": "JavaScript libraries"
        //             }
        //           ],
        //           "latest_version": "1.14",
        //           "Version_Description": "The latest version of jQuery UI is 1.14 and the version you have used is 1.12.1",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnh92-uSiraLbSPaV7nabbOBJyLsVAJ5EYiKaLDgrmEFh8W4g&s"
        //         },
        //         {
        //           "slug": "google-tag-manager",
        //           "name": "Google Tag Manager",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 42,
        //               "slug": "tag-managers",
        //               "name": "Tag managers"
        //             }
        //           ],
        //           "latest_version": "27",
        //           "Version_Description": "The latest version of Google Tag Manager is 27",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS39gHchR8RbXg6DJ9efDAKK2gRDY0ZU-sMe0plDDIhrf6jSL6O&s"
        //         },
        //         {
        //           "slug": "php",
        //           "name": "PHP",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 27,
        //               "slug": "programming-languages",
        //               "name": "Programming languages"
        //             }
        //           ],
        //           "latest_version": "8.3.8",
        //           "Version_Description": "The latest version of PHP is 8.3.8",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjiSJ85C5VzxPEl8pZuZq65yDDOAjRuaJC0AsPgnWjGOpyHn53&s"
        //         },
        //         {
        //           "slug": "datatables",
        //           "name": "DataTables",
        //           "cpe": "cpe:2.3:a:datatables:datatables.net:1.10.12:*:*:*:*:*:*:*",
        //           "versions": [
        //             "1.10.12"
        //           ],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 59,
        //               "slug": "javascript-libraries",
        //               "name": "JavaScript libraries"
        //             }
        //           ],
        //           "latest_version": "2.0.8",
        //           "Version_Description": "The latest version of DataTables is 2.0.8 and the version you have used is 1.10.12",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWUSzALiPhb5OgJcPVZXchGGpHZTNWQiqtbwvw4e79hVraNoc&s"
        //         },
        //         {
        //           "slug": "laravel",
        //           "name": "Laravel",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 18,
        //               "slug": "web-frameworks",
        //               "name": "Web frameworks"
        //             }
        //           ],
        //           "latest_version": "9.x",
        //           "Version_Description": "The latest version of Laravel is 9.x",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHshtX0L054IBiqC7C8sC_W_r4V3VSIvKGQ6ud8E6_qiWWSWI0&s"
        //         },
        //         {
        //           "slug": "jquery",
        //           "name": "jQuery",
        //           "cpe": "cpe:2.3:a:jquery:jquery:2.2.4:*:*:*:*:*:*:*",
        //           "versions": [
        //             "2.2.4"
        //           ],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 59,
        //               "slug": "javascript-libraries",
        //               "name": "JavaScript libraries"
        //             }
        //           ],
        //           "latest_version": "3.7.1",
        //           "Version_Description": "The latest version of jQuery is 3.7.1 and the version you have used is 2.2.4",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQcSmzOJ_MT_e0Mj5HJHqj3SKFWsLyqnR8FF2PlOUYIbdtxDFK&s"
        //         },
        //         {
        //           "slug": "trix",
        //           "name": "Trix",
        //           "versions": [
        //             "0.9.9"
        //           ],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 24,
        //               "slug": "rich-text-editors",
        //               "name": "Rich text editors"
        //             }
        //           ],
        //           "latest_version": "2.1.1",
        //           "Version_Description": "The latest version of Trix is 2.1.1 and the version you have used is 0.9.9",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWfLr_eX3mKPzbzKSWx73DhuOA0JVuTvsPibfFuuya90RQdg&s"
        //         },
        //         {
        //           "slug": "riot",
        //           "name": "Riot",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 12,
        //               "slug": "javascript-frameworks",
        //               "name": "JavaScript frameworks"
        //             }
        //           ],
        //           "latest_version": "2024",
        //           "Version_Description": "The latest version of Riot is 2024",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKGZ_JqaLQeyDw-Jo2PnUheBw8c08rzdDSuj6uys0Z6r53ZIQ&s"
        //         },
        //         {
        //           "slug": "typekit",
        //           "name": "Typekit",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 17,
        //               "slug": "font-scripts",
        //               "name": "Font scripts"
        //             }
        //           ],
        //           "latest_version": "1.10.0",
        //           "Version_Description": "The latest version of Typekit is 1.10.0",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQ80tdWvmErzDO8sSPfjVuqtg2D0hBlv3ZRLHZPOXQfeXFkFg&s"
        //         },
        //         {
        //           "slug": "ubuntu",
        //           "name": "Ubuntu",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 28,
        //               "slug": "operating-systems",
        //               "name": "Operating systems"
        //             }
        //           ],
        //           "latest_version": "24.04",
        //           "Version_Description": "The latest version of Ubuntu is 24.04",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvVftthXo8EBDFTr6VVcJ2__eF-Om1lPSpGsUzAjTO5FP8B-s&s"
        //         },
        //         {
        //           "slug": "bootstrap",
        //           "name": "Bootstrap",
        //           "cpe": "cpe:2.3:a:getbootstrap:bootstrap:3.4.1:*:*:*:*:*:*:*",
        //           "versions": [
        //             "3.4.1"
        //           ],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 66,
        //               "slug": "ui-frameworks",
        //               "name": "UI frameworks"
        //             }
        //           ],
        //           "latest_version": "5.2",
        //           "Version_Description": "The latest version of Bootstrap is 5.2 and the version you have used is 3.4.1",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8d8KW18fzwNVFD_p3oMdQPKMXWEhHPJU3jFZjBpB4isnqjrs&s"
        //         },
        //         {
        //           "slug": "ubuntu",
        //           "name": "Ubuntu",
        //           "versions": [],
        //           "trafficRank": 0,
        //           "confirmedAt": 1718282555,
        //           "categories": [
        //             {
        //               "id": 28,
        //               "slug": "operating-systems",
        //               "name": "Operating systems"
        //             }
        //           ],
        //           "latest_version": "24.04",
        //           "Version_Description": "The latest version of Ubuntu is 24.04",
        //           "img_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvVftthXo8EBDFTr6VVcJ2__eF-Om1lPSpGsUzAjTO5FP8B-s&s"
        //         }

        //       ],
        //       "ipCountry": "US",
        //       "ipRegion": "TX",
        //       "language": "en",
        //       "title": "Welcome - Downtown, TX",
        //       "copyright": "Copyright © 2016-2023 Friends of the THC. Version 2.0.1",
        //       "copyrightYear": 2016,
        //       "responsive": false,
        //       "https": true,
        //       "inferredCompanyName": "DOWNTOWN",
        //       "updatedAt": 1718282554,
        //       "crawlAttemptedAt": 1718282554,
        //       "technologySpend": "Low",
        //       "languages": [
        //         "en"
        //       ],
        //       "ipCountries": [
        //         "US"
        //       ],
        //       "keywords": [
        //         "SITE",
        //         "INFORMATION",
        //         "DOWNTOWN",
        //         "CITY",
        //         "HISTORIC"
        //       ]
        //     }
        //   ]
        // });

      } catch (error) {
        console.error('Error generating response:', error);
      }
    };

    fetchData();
  }, [webAddress,dataRes]);


  const handleDownloadPDF = () => {
    // const content = contentRef.current;
    const content = pdfContent(dataResponse);

    if (!content) {
      console.error('Content reference is null.');
      return;
    }

    var opt = {
      margin: 0,
      filename: 'time_sheet_report.pdf',
      image: { type: 'jpeg', quality: 0.90 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pageBreak: { mode: ['avoid-all', 'css', 'legacy', 'whiteline'] },
      overflow: 'auto',
    };


    html2pdf().set(opt).from(content).save('web_info_page.pdf');
  };

  const handleSendMailPDF = async () => {
    setSending(true);
    const content = pdfContent(dataResponse);

    if (!content) {
      console.error('Content reference is null.');
      setSending(false);
      setDialogOpen(false);
      return;
    }
    var opt = {
      margin: 0,
      filename: 'time_sheet_report.pdf',
      image: { type: 'jpeg', quality: 0.90 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pageBreak: { mode: ['avoid-all', 'css', 'legacy', 'whiteline'] },
      overflow: 'auto',
    };


    const pdfData = await html2pdf().set(opt).from(content).toPdf().output('blob');

    // Send the PDF data to your API
    sendPDFToAPI(pdfData);
  };

  const sendPDFToAPI = async (pdfData) => {
    try {
      const result = await webInfoVM.sendPDFToAPI(email, pdfData, companyName, phone);
      if(result){
        setSending(false);
        setDialogOpen(false);
        setEmail("");
        setCompanyName("");
        setPhone("");
        toast.success('Pdf Sent Successfully', {
          position: 'bottom-right',
          autoClose: 3000,
          color: "blue",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    
      }
     

    } catch (error) {
      setSending(false);
      setDialogOpen(false);
      console.error('Error generating response:', error);
    }

  };





  const pdfInfoView = () => {
    return (
      <section>


        <PdfDialog sending={sending} email={email} companyName={companyName} phone={phone} setEmail={setEmail} setCompanyName={setCompanyName} setPhone={setPhone} open={dialogOpen} onClose={() => setDialogOpen(false)} onAgree={handleSendMailPDF} />

        <section style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>

          <Grid container alignItems="center" className="header-box" spacing={2} padding= '0 80px 0 100px' >
            <Grid item xs={12} sm={1.5}>
              <img src={logo} alt="Logo" className="logo" style={{ width: '250px' }} />
            </Grid>
            <Grid item xs={12} sm={7.5} />
            <Grid item xs={12} sm={3} container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => setDialogOpen(true)}
                  fullWidth
                >
                  Send Via Mail
                </Button>
              </Grid>
              {/* <Grid item>
              <Button
                variant="contained"
                endIcon={<DownloadIcon />}
                onClick={handleDownloadPDF}
                fullWidth
              >
                Download
              </Button>
            </Grid> */}
            </Grid>
          </Grid>
          <Grid ref={contentRef} sx={{ paddingX: { sm: "1px", md: "200px", xs: "1px" }, paddingTop:"50px" }}>
            <div style={{ flex: 1, display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: "20px", marginLeft: "40px", marginRight: "40px", flexDirection: "column" }}>
              <div className="text-container" style={{ fontSize: "25px", color: appColors.blueColor, fontWeight: "800", fontFamily: "Lato, sans-serif" }}>
                {dataResponse != null ? `${dataResponse.data[0]?.title}` : ''}
              </div>
              <div className="text-container" style={{ fontSize: "px", color: appColors.darkGreyColor, fontWeight: "400", marginTop: 10, fontFamily: "Lato, sans-serif" }}>
                {dataResponse.data[0]?.url}
              </div>
              <div style={{ marginTop: "25px", flex: 1, width: '100%', paddingTop: "2px", paddingBottom: "2px", backgroundColor: appColors.blueColor }}></div>
            </div>

            <Container
              maxWidth={false}
              style={{
                border: `2px solid ${AppColors.blueGradientColor1}`,
                width: 'auto',
                padding: 0,
                margin: '40px',
                borderRadius: '20px', // optional, for rounded corners
              }}
            >
              <Grid container st>
                <Grid item xs={12} md={3} style={{
                  borderTopLeftRadius: '17px',
                  borderBottomLeftRadius: '17px',
                  backgroundColor: AppColors.blueGradientColor1,
                  background: ` linear-gradient(90deg, ${AppColors.blueGradientColor1} 0%, ${AppColors.blueGradientColor2} 69%) `, display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                  <Typography variant="h6" style={{ fontFamily: "Lato, sans-serif", fontSize: "16px", fontWeight: 800, color: "white", margin: "20px" }}>About</Typography>
                </Grid>
                <Grid item xs={12} md={9} style={{ paddingTop: '16px', paddingBottom: '16px', paddingLeft: '25px', paddingRight: "16px" }}>
                  <Typography gutterBottom>
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 800, color: AppColors.blueColor }}>
                      Title:</span>
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 400, fontSize: '14px', paddingLeft: "10px", color: AppColors.darkGreyColor }}>
                      {' '}{dataResponse.data[0].title ?? ''}
                    </span>
                  </Typography>

                  <Typography gutterBottom marginTop="15px">
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 800, color: AppColors.blueColor }}> Copyright: </span>
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 400, fontSize: '14px', paddingLeft: "10px", color: AppColors.darkGreyColor }}>
                      {dataResponse.data[0].copyright ?? ''}
                    </span>
                  </Typography>

                  <Typography gutterBottom marginTop="15px">
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 800, color: AppColors.blueColor }}> Company information:</span>
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 400, fontSize: '14px', paddingLeft: "10px", color: AppColors.darkGreyColor }}>Inferred company name: {dataResponse.data[0].inferredCompanyName ?? ''}</span>
                  </Typography>

                  <Typography gutterBottom marginTop="15px">
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 800, color: AppColors.blueColor }}> Signals:</span>
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 400, fontSize: '14px', paddingLeft: "10px", color: AppColors.darkGreyColor }}>Technology spend: {dataResponse.data[0].technologySpend ?? ''}</span>

                  </Typography>

                  <Typography gutterBottom marginTop="15px">
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 800, color: AppColors.blueColor }}> Locale:</span>
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 400, fontSize: '14px', paddingLeft: "10px", color: AppColors.darkGreyColor }}> Region: {dataResponse.data[0].ipRegion ?? ''}, Countries: {dataResponse.data[0].ipCountry ?? ''}, Languages: {dataResponse.data[0].ipCountry ?? ''}</span>
                  </Typography>



                  <Typography gutterBottom marginTop="15px">
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 800, color: AppColors.blueColor }}> Keywords:</span>
                    <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 400, fontSize: '14px', paddingLeft: "10px", color: AppColors.darkGreyColor }}>{dataResponse.data[0].keywords?.join(', ') ?? ''}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Container>


            <Grid >
              <div className="text-container" style={{
                fontSize: "25px",
                color: appColors.blueColor, fontWeight: "bold", flex: 1, display: "flex", alignItems: 'center', justifyContent: 'center',
              }}>Technologies Stack</div>
              {dataResponse.data[0].technologies.map((tech, index) => (
                <Box key={index} marginY={0} style={{ pageBreakInside: "avoid" }}>
                  <Container
                    maxWidth={false}
                    style={{
                      border: `2px solid ${AppColors.blueGradientColor1}`,
                      width: 'auto',
                      padding: 0,
                      margin: '40px',
                      borderRadius: '20px', // optional, for rounded corners
                    }}>
                    <Grid container >
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                          backgroundColor: AppColors.blueGradientColor1,
                          background: ` linear-gradient(90deg, ${AppColors.blueGradientColor1} 0%, ${AppColors.blueGradientColor2} 69%) `,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderTopLeftRadius: '17px',
                          borderBottomLeftRadius: '17px',
                        }}
                      >
                        <Typography variant="h7" style={{ fontFamily: "Lato, sans-serif", fontWeight: 800, marginBottom: '8px', marginTop: "8px", textAlign: "center", color: AppColors.whiteColor }}>
                          {tech.categories && tech.categories.length > 0 ? tech.categories[0].name : ""}
                        </Typography>

                        <img
                          src={tech.img_url}
                          // alt={tech.categories[0].name}
                          style={{
                            height: '50px',
                            width: '50px'
                          }}
                        />
                        <Typography variant="h9" style={{ fontSize: "12px", fontFamily: "Lato, sans-serif", fontWeight: 400, marginTop: '8px', marginBottom: '8px', color: AppColors.greyColor2 }}>
                          {tech.slug}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9} style={{ paddingTop: '16px', paddingBottom: '16px', paddingLeft: '25px', paddingRight: "16px" }}>
                        <Typography gutterBottom >
                          <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 800, color: AppColors.blueColor }}>Impact:</span>
                          <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 400, fontSize: "14px", paddingLeft: "10px", color: AppColors.darkGreyColor }}>{compareVersions(tech.versions[0], tech.latest_version)}</span>

                        </Typography>

                        <Typography gutterBottom marginTop="15px">
                          <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 800, color: AppColors.blueColor }}> Current Version:</span>
                          <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 400, fontSize: "14px", paddingLeft: "10px", color: AppColors.darkGreyColor }}>{tech.versions.length ? tech.versions[0] : "N/A"}</span>
                        </Typography>

                        <Typography gutterBottom marginTop="15px">
                          <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 800, color: AppColors.blueColor }}>Latest Version:</span>
                          <span style={{ fontFamily: "Lato, sans-serif", fontWeight: 400, fontSize: "14px", paddingLeft: "10px", color: AppColors.darkGreyColor }}>{tech.latest_version === "" || tech.latest_version === null ? "N/A" : tech.latest_version}</span>

                        </Typography>






                      </Grid>
                    </Grid>
                  </Container>


                </Box>
              ))}
            </Grid>
          </Grid>

        </section>

      </section>
    );
  }

  const pdfLoaderView = () => {
    return (
      <section>
        <Grid
          container
          style={{ height: '100vh' }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box >
            <CircularProgress variant="indeterminate" style={{ color: appColors.primaryColor }} />
          </Box>
          <div className="text-container" style={{ margin: "10px", fontSize: "20px", color: appColors.primaryColor, fontWeight: "normal", marginTop: 2 }}>
            Loading.....
          </div>
        </Grid>
      </section>
    );
  }

  const pdfDataNotFoundView = () => {
    return (
      <section>
        <Grid
          container
          style={{ height: '100vh' }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >

          <div className="text-container" style={{ margin: "10px", fontSize: "20px", color: appColors.primaryColor, fontWeight: "normal", marginTop: 2 }}>
            No data Found
          </div>
        </Grid>
      </section>
    );
  }


  return (
    <section>
      {dataResponse !== null && dataResponse !== undefined ? (
        pdfInfoView()
      ) : dataResponse === undefined ? pdfDataNotFoundView() : (
        pdfLoaderView()
      )}
    </section>
  );
}

export default WebInfoPage;
