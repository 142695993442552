import axios from "axios";
import React from "react";
 
class APIClient extends React.Component {
    static apiUrl = 'https://analyzerapi.infodevbox.com/';

    static async postRequest(endpoint, params = {}, needAuthorization) {
        try {
            const parameters = JSON.stringify(params);
            // const userToken = await LocalStorageHelper.getUserToken();
 
            const emptyHeader = { 'Content-Type': 'application/json' };
            const authHeader = { 'Content-Type': 'application/json' };
 
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: this.apiUrl + endpoint,
                headers: needAuthorization ? authHeader : emptyHeader,
                data: parameters
            };
            console.log("config", config);

            const response = await axios.request(config);
            console.log("res==>>", response.data);
            return response.data;
        } catch (err) {
            console.error("Error in postRequest:", err);
            if (err.response && err.response.data && err.response.data.message) {
                alert(err.response.data.message);
            } else {
                alert("An error occurred");
            }
        }
    }

    static async postDocumentRequest(endpoint, params = {}, needAuthorization) {
        const formData = new FormData();
        formData.append('email', params.email);
        formData.append('company_name', params.company_name);
        formData.append('contact_no', params.contact_no);
        formData.append('pdf', params.pdfData, 'technology-stack.pdf');
    
        try {
            const response = await fetch(this.apiUrl + endpoint, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error in postDocumentRequest:", error);
            throw error;
        }
    }
}
 
export default APIClient;